import { render, staticRenderFns } from "./Privacy.vue?vue&type=template&id=97ffb260&scoped=true&"
var script = {}
import style0 from "./Privacy.vue?vue&type=style&index=0&id=97ffb260&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ffb260",
  null
  
)

export default component.exports